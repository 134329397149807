@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

 
a:active {
  color: inherit !important;
}

.PhoneInputInput:focus {
  outline: none !important;
}

.PhoneInputInput {
  background: transparent !important;
}


li::marker {
  color: #70090A;
}

ul {
  list-style-type: disc;
  padding-left: 20px; /* Ensure some padding so the bullets are not cut off */
}

 
 