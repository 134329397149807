.rs-navbar-brand {
  padding: 10px !important;
}

.rs-navbar-item {
  padding: 10px !important;
  color: white !important;
}

.rs-navbar-default .rs-dropdown .rs-dropdown-toggle:hover,
.rs-navbar-default .rs-dropdown.rs-dropdown-open .rs-dropdown-toggle,
.rs-navbar-default .rs-navbar-item:hover {
  background-color: transparent !important;

  color: #f6c47e !important;
}

.rs-navbar-default {
  background-color: #70090a !important;
}

.rs-navbar .rs-dropdown-item:hover {
  background-color: #70090a !important;
  color: #f6c47e !important;
}

.rs-navbar .rs-dropdown-item {
  background-color: white !important;
  color: black !important;
}

a {
  color: inherit !important;
  text-decoration: none !important;
}


@media (max-width: 768px) {
  .rs-navbar-item {
    height: 45px !important;
    
  }
}